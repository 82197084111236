@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.ocean {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #B7D4EA;
  left: 0;
  height: 0;
}


.wave {
  /* background: url('https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg') repeat-x; */
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='600' %3E%3Cdefs%3E%3C/defs%3E%3Cpath fill='%23d0eef7' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z' transform='matrix(-1 0 0 1 1600 0) scale(1 1.2)'/%3E%3C/svg%3E");
  /* background-size: 60rem 253px; */
  position: fixed;
  /* top: -160px; */
  /* bottom: -130; */
  max-width: 56rem;
  width: 100%;
  height: 400px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.6;
  z-index: 0;
}


.wave:nth-of-type(2) {
  /* bottom: -100; */
  animation: wave 5s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 5s ease -1.25s infinite;
  opacity: 1;
}

.wave:nth-of-type(3) {
  /* bottom: -130; */
  animation: wave 13s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.325s infinite, swell 10s ease -1.25s infinite;
  opacity: 0.4;
}



@keyframes wave {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 100rem;
  }
}

/* @keyframes swell {

  0%,
  100% {
      transform: translate3d(0, -25px, 0);
  }

  50% {
      transform: translate3d(0, 5px, 0);
  }
} */

.endWave {
  display: none;
}

.temp-selected {
  border: 2px dashed #394980;
}

.dryer-machine {
  background-image: url('./images/dr.png');
  display: inline-block;
  margin-right: 10px;
  position: relative;
  font-size: 10pt;
  font-weight: bold;
  color: #f9b115;
  margin-top: -20px;
}

.dryer-machine.size-12 {
  width: 90px;
  height: 145px;
  background-size: 90px 145px;
}

.dryer-machine.size-12 .status {
  position: absolute;
  top: 46px;
  left: 7px;
  background-size: 72px 58px;
  width: 72px;
  height: 58px;
  opacity: 0.8;
}

.dryer-machine.size-12 .timer {
  position: absolute;
  top: 67px;
  font-size: 10px;
  width: inherit;
  text-align: center;
  font-weight: bold;
  left: 18px;
}

.dryer-machine .type {
  position: absolute;
  bottom: 5px;
  right: 0px;
  font-size: 11px;
  color: #222222 !important;
}

.dryer-machine .name {
  position: absolute;
  top: 39px;
  left: 72px;
  font-size: 12px;
}

.temp-low {
  position: absolute;
  bottom: 29px;
  left: 37px;
  font-size: 8px;
  color: white;
  background-color: dodgerblue;
  padding-left: 2px;
  padding-right: 2px;
}

.temp-med {
  position: absolute;
  bottom: 29px;
  left: 37px;
  font-size: 8px;
  color: white;
  background-color: darkorange;
  padding-left: 2px;
  padding-right: 2px;
}

.temp-high {
  position: absolute;
  bottom: 29px;
  left: 37px;
  font-size: 8px;
  color: white;
  background-color: red;
  padding-left: 2px;
  padding-right: 2px;
}

.DRY-IDLE {
  background-image: url('./images/status-ok.png');
}

.DRY-DISCONNECT {
  background-image: url('./images/status-offline.png');
}

.DRY-RUNNING {
  background-image: url('./images/drying.gif');
}

.DRY-ERROR {
  background-image: url('./images/status-error.png');
}

.washing-machine {
  background-image: url('./images/ws.png');
  display: inline-block;
  margin-right: 10px;
  position: relative;
  font-size: 10pt;
  color: #0074d9;
  font-weight: bold;
}

.washing-machine.size-12 {
  width: 80px;
  height: 120px;
  background-size: 80px 120px;
}

.washing-machine.size-12 .status {
  position: absolute;
  top: 43px;
  left: 13px;
  background-size: 53px 47px;
  width: 53px;
  height: 48px;
  opacity: 0.8;
}

.washing-machine.size-12 .timer {
  position: absolute;
  top: 57px;
  left: 25px;
  width: inherit;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
}

.washing-machine .type {
  position: absolute;
  bottom: 3px;
  right: 1px;

  font-size: 10px;
}

.washing-machine .name {
  position: absolute;
  bottom: 75px;
  left: 4px;
  font-size: 11px;
  color: black;
}


.temp-cold {
  position: absolute;
  bottom: 29px;
  left: 30px;
  font-size: 8px;
  color: white;
  background-color: dodgerblue;
  padding-left: 2px;
  padding-right: 2px;
}

.temp-warm {
  position: absolute;
  bottom: 29px;
  left: 30px;
  font-size: 8px;
  color: white;
  background-color: darkorange;
  padding-left: 2px;
  padding-right: 2px;
}

.temp-hot {
  position: absolute;
  bottom: 29px;
  left: 30px;
  font-size: 8px;
  color: white;
  background-color: red;
  padding-left: 2px;
  padding-right: 2px;
}

.WASH-RUNNING {
  background-image: url('./images/washing.gif');
}

.WASH-ACTIVE {
  background-image: url('./images/status-ok.png');
}

.WASH-IDLE {
  background-image: url('./images/status-ok.png');
}

.WASH-DISCONNECT {
  background-image: url('./images/status-offline.png');
}

.WASH-DRYING {
  background-image: url('./images/drying.gif');
}

.WASH-ERROR {
  background-image: url('./images/status-error.png');
}

.outer {
  /* border:1px solid blue; */
  position: relative;
}

.inner {
  left: 52%;
  top: 52%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  height: 20px;
}

.header {
  position: relative;
}

.header-background {
  background-color: #0E3D67;
  position: relative;
}

.tm-header-background {
  position: relative;
}

.div-coupon {
  input[type="checkbox"] {

    &+span {
      visibility: hidden;
      opacity: 0;

      transition: visibility 0s linear 0.33s, opacity 0.33s linear;
    }

    &:checked+span {
      visibility: visible;
      opacity: 1;

      transition-delay: 0s;
    }
  }
}