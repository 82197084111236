@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Kanit');

*{
  font-family: 'Kanit';
}

body {
  margin: 0;
  font-family: 'Kanit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.thai-qr-content {
  /* height: calc(100vh - 236px);
  height: -webkit-calc(100vh - 236px);
  height: -moz-calc(100vh - 236px); */
  height: calc(100vh - 33vh);
  height: -webkit-calc(100vh - 33vh);
  height: -moz-calc(100vh - 33vh);
}

.truemoney-qr-content {
  /* height: calc(100vh - 296px);
  height: -webkit-calc(100vh - 296px);
  height: -moz-calc(100vh - 296px); */
  height: calc(100vh - 42vh);
  height: -webkit-calc(100vh - 42vh);
  height: -moz-calc(100vh - 42vh);
}

.linepay-qr-content {
  /* height: calc(100vh - 296px);
  height: -webkit-calc(100vh - 296px);
  height: -moz-calc(100vh - 296px); */
  height: calc(100vh - 42vh);
  height: -webkit-calc(100vh - 42vh);
  height: -moz-calc(100vh - 42vh);
}

.fade-in {
  opacity: 0;
  animation: fade-in .5s ease-in forwards;
}

.fade-out {
  opacity: 1;
  animation: fade-out 4s 1s ease-out forwards;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-error-bg-pulse {
  animation-name: color;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  animation-direction:alternate-reverse;
  animation-timing-function:ease
}

@keyframes color {
  /* from {background-color: red;}
  to {background-color: inherit;} */
  from {
    @apply bg-red-400;
  }

  to {
    @apply bg-red-50;
  }
}